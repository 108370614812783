.item {
  box-sizing: border-box;
  border-radius: 4px;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.item.is-dragging {
  border-color: rgba(0,0,0,0.1);
  box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px rgba(0,0,0,0.1);
}

.no-scrollbars{

  mask-image: linear-gradient(to top, transparent, black 2rem);

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.no-scrollbars::-webkit-scrollbar { 
  display: none;  
}

/*
.no-scrollbars-bottom{
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1),rgba(0, 0, 0, 1), transparent 100%);

  transition: 330ms;

  
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.no-scrollbars-bottom::-webkit-scrollbar { 
display: none;  
}
*/

.no-scrollbars-top{

  /*mask-image: linear-gradient(to bottom, transparent, black 10rem); */
  /*mask-image: linear-gradient(to top, transparent, black 10rem);*/

  mask-image: linear-gradient(to bottom, transparent, black 2rem);

  animation: ToTop 2s forwards;
  animation-iteration-count: 1;
}

@keyframes ToTop {
  from {
    mask-position: 0vw -100vh;
  }
  to {
    mask-position: 0vw 0vh;
  }
}

/*

.no-scrollbars-two{

  mask: 
  linear-gradient(to bottom, transparent, black 10rem) top, 
  linear-gradient(to top, transparent, black 10rem) bottom;


  mask-size:100% 90%;
  mask-repeat:no-repeat; 

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
*/